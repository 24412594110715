

.bodyDiv {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #fffbf3;
    overflow-x: hidden;
}


.MainHead {
    position: relative;
    width: 100%;
}

.MainHead > h1 {
    margin: 0px;
    text-align: end;
}

.text {
    margin-top: 3em;
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
}

.text > p {
    height: 100%;
    margin-block-end: 5em;
}

.imgContainer {
    position: relative;
    width: 30%;
    margin: 1%;
    box-sizing: border-box;
    background-color: #48604f57;
    transition: all 0.2s;
    cursor: pointer;
    background-size: cover;
    background-position: center;
}

.imgContainer::before {
    content: "";
    display: block;
    padding-top: 100%;
}

.grid {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: -1%;
    justify-content: center;
    margin-bottom: 10em;
    margin-top: 4em;
}

.openZoom {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    cursor: pointer;
}

.closedZooom {
    display: none;
}

@media screen and (min-width: 1300px) {
    .MainDiv {
        position: relative;
        width: 70%;
        left: 15%;
    }
}

@media screen and (min-width: 900px) and (max-width: 1300px) {
    .MainDiv {
        position: relative;
        width: 80%;
        left: 10%;
    }
}

@media screen and (min-width: 550px) and (max-width: 900px) {
    .MainDiv {
        position: relative;
        width: 90%;
        left: 5%;
    }
}

@media screen and (min-width: 350px) and (max-width: 550px) {
    .MainDiv {
        position: relative;
        width: 90%;
        left: 5%;
    }
    .MainHead > h1 {
        text-align: center;
    }
}

@media screen and (min-width: 200px) and (max-width: 350px) {
    .MainDiv {
        position: relative;
        width: 90%;
        left: 5%;
    }
}
