

.bodyDiv {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #fffbf3;
    overflow-x: hidden;
}

.MainDiv > h1 {
    width: 100%;
    text-align: end;
}

h2 {
    margin: 0px;
    font-family: "Inter";
    color: #0d3527;
    font-weight: bold;
}

p {
    text-align: justify;
}

.imgContainer > div {
    position: relative;
    width: 100%;
    height: 100%;
    display: inherit;
    justify-content: inherit;
}

.img1,
.img2,
.img3 {
    background-color: #48604f57;
}
.cardsContainer > div {
    transition: 0.2s;
}

.cardsContainer > div:hover {
    transform: scale(1.1);
}

.cardsContainer > div > button {
    padding: 0px;
    margin: 0px;
    border: none;
    position: relative;
    background-color: transparent;
    height: 100%;
    width: 100%;
}

.cardsContainer > div > button > img {
    background-color: #48604f57;
    height: 90%;
    width: 100%;
    object-fit: cover;
}

.mariageCats {
    position: relative;
    width: 80%;
    left: 10%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 10em;
    margin-bottom: 5em;
}

.imgContainer > div > img {
    object-fit: cover;
}

.category  {
    appearance: none;
    border : none;
    padding-block: 0px;
    margin: 0px;
    padding-inline: 0px;
}

.lisianthus1 {
    display: none;
}

@media screen and (min-width: 1300px) {

    .lisianthus1 {
        height: 500px;
        position: absolute;
        display: block;
        left: 3%;
        top: 2%;
    }

    .MainDiv {
        position: relative;
        width: 70%;
        left: 15%;
    }

    .cardsContainer {
        position: relative;
        left: 10%;
        width: 80%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 5em;
        margin-bottom: 10em;
    }

    .cardsContainer > div {
        width: 19%;
        height: 19vw;
    }
    .cardsContainer > div > h3 {
        text-align: center;
        font-size: 21px;
        margin-left: 3px;
        margin-right: 3px;
    }

    .imgContainer {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;
        height: 36em;
    }

    .imgContainer > div > img {
        width: 30%;
        height: 50%;
    }

    .imgContainer > .centerer1 > .img2 {
        position: relative;
        top: 25%;
    }


    .category  {
        position: relative;
        width: 100%;
        height: 4em;
        align-items: center;
        display: flex;
        margin-bottom: 2em;
        background-color: #48604f57;
        box-shadow: 5px 5px 1px 0px #48604f57;
        cursor: pointer;
        transition: 0.2s;
        
    }
    
    .category:active {
        transform: translate(5px, 5px);
        box-shadow: 0px 0px 0px 0px #48604f57;
    }

    
    .category>h2 {
        position: relative;
        font-family: "Inter";
        color: #0d3527;
        margin-left: 1em;
        font-size: 25px;
    }
}

@media screen and (min-width: 900px) and (max-width: 1300px) {

    .lisianthus1 {
        height: 300px;
        position: absolute;
        display: block;
        left: 0%;
        top: 6%;
    }

    .MainDiv {
        position: relative;
        width: 80%;
        left: 10%;
    }

    .cardsContainer {
        position: relative;
        left: 5%;
        width: 90%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 5em;
        margin-bottom: 8em;
    }

    .cardsContainer > div > h3 {
        text-align: center;
        font-size: 18px;
        margin-left: 3px;
        margin-right: 3px;
    }

    .cardsContainer > div {
        width: 19%;
        height: 25vw;
    }

    @media screen and (min-width: 1100px) and (max-width: 1300px) {
        .imgContainer {
            position: relative;
            width: 100%;
            display: flex;
            justify-content: space-between;
            height: 29em;
        }
    }

    @media screen and (min-width: 900px) and (max-width: 1100px) {
        .imgContainer {
            position: relative;
            width: 100%;
            display: flex;
            justify-content: space-between;
            height: 27em;
        }
    }
    .imgContainer > div > img {
        width: 30%;
        height: 50%;
    }

    .imgContainer > .centerer1 > .img2 {
        position: relative;
        top: 25%;
    }

    .category  {
        position: relative;
        width: 100%;
        height: 3.6em;
        align-items: center;
        display: flex;
        margin-bottom: 2em;
        background-color: #48604f57;
        box-shadow: 5px 5px 1px 0px #48604f57;
        cursor: pointer;
        transition: 0.2s;
    }
    
    .category:active {
        transform: translate(5px, 5px);
        box-shadow: 0px 0px 0px 0px #48604f57;
    }

    
    .category>h2 {
        position: relative;
        font-family: "Inter";
        color: #0d3527;
        margin-left: 1em;
        font-size: 20px;
    }

    
}

@media screen and (min-width: 550px) and (max-width: 900px) {

    .lisianthus1 {
        height: 200px;
        position: absolute;
        display: block;
        left: 3%;
        top: 4%;
    }

    .MainDiv {
        position: relative;
        width: 90%;
        left: 5%;
    }

    .cardsContainer {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 5em;
        margin-bottom: 6em;
    }

    .cardsContainer > div {
        width: 19%;
        height: 31vw;
    }

    @media screen and (min-width: 700px) and (max-width: 900px) {
        .imgContainer {
            position: relative;
            width: 100%;
            display: flex;
            justify-content: space-between;
            height: 24em;
        }

        .cardsContainer > div > h3 {
            text-align: center;
            font-size: 15px;
            margin-left: 3px;
            margin-right: 3px;
        }
    }

    @media screen and (min-width: 550px) and (max-width: 700px) {
        .imgContainer {
            position: relative;
            width: 100%;
            display: flex;
            justify-content: space-between;
            height: 19em;
        }

        .cardsContainer > div > h3 {
            text-align: center;
            font-size: 13px;
            margin-left: 3px;
            margin-right: 3px;
        }
    }

    .imgContainer > div > img {
        width: 30%;
        height: 50%;
    }

    .imgContainer > .centerer1 > .img2 {
        position: relative;
        top: 25%;
    }

    .category  {
        position: relative;
        width: 100%;
        height: 3.2em;
        align-items: center;
        display: flex;
        margin-bottom: 1.8em;
        background-color: #48604f57;
        box-shadow: 5px 5px 1px 0px #48604f57;
        cursor: pointer;
        transition: 0.2s;
    }
    
    .category:active {
        transform: translate(5px, 5px);
        box-shadow: 0px 0px 0px 0px #48604f57;
    }

    
    .category>h2 {
        position: relative;
        font-family: "Inter";
        color: #0d3527;
        margin-left: 1em;
        font-size: 18px;
    }

    .mariageCats {
        position: relative;
        width: 90%;
        left: 5%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-top: 5em;
        margin-bottom: 1em;
    }
}

@media screen and (min-width: 350px) and (max-width: 550px) {
    .MainDiv {
        position: relative;
        width: 90%;
        left: 5%;
    }

    .cardsContainer {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 5em;
        margin-bottom: 5em;
    }

    @media screen and (min-width: 450px) and (max-width: 550px) {
        .cardsContainer > div > h3 {
            text-align: center;
            font-size: 10px;
            margin-left: 3px;
            margin-right: 3px;
        }
    }

    @media screen and (min-width: 350px) and (max-width: 450px) {
        .cardsContainer > div > h3 {
            text-align: center;
            font-size: 8px;
            margin-left: 3px;
            margin-right: 3px;
        }
    }

    .cardsContainer > div {
        width: 19%;
        height: 31vw;
    }
    .MainDiv > h1 {
        text-align: center;
    }

    .imgContainer {
        position: relative;
        height: 32em;
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 5em;
        
    }

    .imgContainer > div {
        flex-direction: column;
        align-items: center;
    }

    .centerer1 {
        transform: translate(-12.5%, 0%);
    }
    .imgContainer > div > img {
        width: 13em;
        height: 30%;
    }

    .imgContainer > .centerer1 > .img2 {
        position: relative;
        left: 25%;
    }

    .category  {
        position: relative;
        width: 100%;
        height: 2.8em;
        align-items: center;
        display: flex;
        margin-bottom: 1.2em;
        background-color: #48604f57;
        box-shadow: 3px 3px 1px 0px #48604f57;
        cursor: pointer;
        transition: 0.2s;
    }
    
    .category:active {
        transform: translate(3px, 3px);
        box-shadow: 0px 0px 0px 0px #48604f57;
    }

    
    .category>h2 {
        position: relative;
        font-family: "Inter";
        color: #0d3527;
        margin-left: 1em;
        font-size: 16px;
    }

    .mariageCats {
        position: relative;
        width: 100%;
        left: 0%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-top: 5em;
        margin-bottom: 3em;
    }
}

@media screen and (min-width: 200px) and (max-width: 350px) {
    .MainDiv {
        position: relative;
        width: 90%;
        left: 5%;
    }

    .cardsContainer {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 5em;
        margin-bottom: 5em;
    }

    .cardsContainer > div {
        width: 19%;
        height: 31vw;
    }

    .cardsContainer > div > h3 {
        text-align: center;
        font-size: 6.5px;
        margin-left: 3px;
        margin-right: 3px;
    }

    .MainDiv > h1 {
        text-align: center;
    }

    .imgContainer {
        position: relative;
        height: 27em;
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 5em;
    }

    .imgContainer > div {
        flex-direction: column;
        align-items: center;
    }

    .centerer1 {
        transform: translate(-12.5%, 0%);
    }

    .imgContainer > div > img {
        width: 10em;
        height: 30%;
    }

    .imgContainer > .centerer1 > .img2 {
        position: relative;
        left: 25%;
    }

    .category  {
        position: relative;
        width: 100%;
        height: 2.4em;
        align-items: center;
        display: flex;
        margin-bottom: 1.2em;
        background-color: #48604f57;
        box-shadow: 3px 3px 1px 0px #48604f57;
        cursor: pointer;
        transition: 0.2s;
    }
    
    .category:active {
        transform: translate(3px, 3px);
        box-shadow: 0px 0px 0px 0px #48604f57;
    }

    
    .category>h2 {
        position: relative;
        font-family: "Inter";
        color: #0d3527;
        margin-left: 1em;
        font-size: 14px;
    }

    .mariageCats {
        position: relative;
        width: 100%;
        left: 0%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-top: 5em;
        margin-bottom: 3em;
    }
}
