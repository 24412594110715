

.bodyDiv {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #FFFBF3;
    overflow-x: hidden;
}

.MainDiv>h1 {
    width: 100%;
    text-align: end;
}


h2 {
    margin: 0px;
    font-family: "Inter";
    color: #0d3527;
    font-weight: bold;
}

p {
    text-align: justify;
}


.imgContainer {
    margin-bottom: 4em;
}

.imgContainerFive {
    margin-bottom: 9.5em;
}

.imgContainerFivePhone {
    margin-bottom: 4em;
}

.imgContainer>div {
    position: relative;
    width: 100%;
    height: 100%;
    display: inherit;
    justify-content: inherit;
}

.imgContainerFivePhone>div {
    position: relative;
    width: 100%;
    height: 100%;
    display: inherit;
    justify-content: inherit;
}

.img1, .img2, .img3 {
    background-color: #48604f57;
}

.intro {
    margin-bottom: 4em;
}

.imgContainer>div>img {
    object-fit: cover;
}

.centerer3>div>img {
    object-fit: cover;
}

.lisianthus1 {
    display: none;
}

.lisianthus2 {
    display: none;
}



@media screen and (min-width: 1300px) {

    .lisianthus1 {
        height: 580px;
        position: absolute;
        display: block;
        left: 1%;
        top: 7%;
        transform: scale(-1, 1);
    }

    .lisianthus2 {
        height: 580px;
        position: absolute;
        display: block;
        right: -0.5%;
        top: 70%;
    }

    .MainDiv {
        position: relative;
        width: 70%;
        left: 15%;
    }

    h2 {
        font-size: 40px;
    }

    .imgContainer {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;
        height: 26em;
    }


    
    .imgContainer>div>img {
        width: 30%;
        height: 50%;
    }

    .imgContainer>.centerer1>.img2 {
        position: relative;
        top: 25%;
    }

    .imgContainer>.centerer2>.img1, .imgContainer>.centerer2>.img3 {
        position: relative;
        top: 25%;
    }


    .imgContainerFive {
        position: relative;
        width: 100%;
        display: flex;
        height: 33em;
    }

    .imgContainerFivePhone {
        display: none;
    }

    .centerer3 {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
    }

    .centerer3>div {
        width: 100%;
        display: flex;
        height: 13em;
    }

    .centerer3>div>img {
        width: 30%;
    }
    .centerer3>.line1 {
        justify-content: space-between;
    }
    .centerer3>.line2 {
        position:absolute;
        justify-content: center;
        transform: translate(0%, 75%);
    }
}


@media screen and (min-width: 900px) and (max-width: 1300px) {
    .MainDiv {
        position: relative;
        width: 80%;
        left: 10%;
    }

    @media screen and (min-width: 1100px) and (max-width: 1300px) {
        .lisianthus1 {
            height: 300px;
            position: absolute;
            display: block;
            left: 0%;
            top: 10%;
            transform: scale(-1, 1);
        }

        .lisianthus2 {
            height: 300px;
            position: absolute;
            display: block;
            right: 0%;
            top: 73%;
        }

    }

    @media screen and (min-width: 900px) and (max-width: 1100px) {
        .lisianthus1 {
            height: 240px;
            position: absolute;
            display: block;
            left: 1%;
            top: 10%;
            transform: scale(-1, 1);
        }

        .lisianthus2 {
            height: 240px;
            position: absolute;
            display: block;
            right: 0%;
            top: 75%;
        }

    }

    .imgContainer {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;
        height: 22em;
    }

    .imgContainer>div>img {
        width: 30%;
        height: 50%;
    }

    .imgContainer>.centerer1>.img2 {
        position: relative;
        top: 25%;
    }

    .imgContainer>.centerer2>.img1, .imgContainer>.centerer2>.img3 {
        position: relative;
        top: 25%;
    }



    .imgContainerFive {
        position: relative;
        width: 100%;
        display: flex;
        height: 28em;
    }

    .centerer3 {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
    }

    .centerer3>div {
        width: 100%;
        display: flex;
        height: 11em;
    }

    .centerer3>div>img {
        width: 30%;
    }
    .centerer3>.line1 {
        justify-content: space-between;
    }
    .centerer3>.line2 {
        position:absolute;
        justify-content: center;
        transform: translate(0%, 75%);
    }
}

@media screen and (min-width: 550px) and (max-width: 900px) {
    
    .MainDiv {
        position: relative;
        width: 90%;
        left: 5%;
    }

    .imgContainer {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;
        height: 18em;
    }

    .imgContainer>div>img {
        width: 30%;
        height: 50%;
    }


    .imgContainer>.centerer1>.img2 {
        position: relative;
        top: 25%;
    }

    .imgContainer>.centerer2>.img1, .imgContainer>.centerer2>.img3 {
        position: relative;
        top: 25%;
    }

    .imgContainerFive {
        position: relative;
        width: 100%;
        display: flex;
        height: 22em;
    }

    .imgContainerFivePhone {
        display: none;
    }

    .centerer3 {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
    }

    .centerer3>div {
        width: 100%;
        display: flex;
        height: 9em;
    }

    .centerer3>div>img {
        width: 30%;
    }
    .centerer3>.line1 {
        justify-content: space-between;
    }
    .centerer3>.line2 {
        position:absolute;
        justify-content: center;
        transform: translate(0%, 75%);
    }
}

@media screen and (min-width: 350px) and (max-width: 550px) {
    .MainDiv {
        position: relative;
        width: 90%;
        left: 5%;
    }
    .MainDiv>h1 {
        text-align: center;
    }

    .imgContainer {
        position: relative;
        height: 25em;
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .imgContainer>div {
        flex-direction: column;
        align-items: center;
    }

    .centerer1, .centerer2 {
        transform: translate(-12.5%, 0%);
    }
    .imgContainer>div>img {
        width: 13em;
        height: 30%;
    }

    .imgContainer>.centerer1>.img2 {
        position: relative;
        left: 25%;
    }

    .imgContainer>.centerer2>.img1, .imgContainer>.centerer2>.img3 {
        position: relative;
        left: 25%;
    }




    .imgContainerFive {
        display: none;
    }

    .imgContainerFivePhone {
        position: relative;
        height: 41.666em;
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .imgContainerFivePhone>div {
        flex-direction: column;
        align-items: center;
    }

    .imgContainerFivePhone>div>div {
        width: 13em;
        height: 18%;
    }

    .imgContainerFivePhone>.centerer1>.img1 {
        position: relative;
        left: 25%;
    }

}

@media screen and (min-width: 200px) and (max-width: 350px) {
    .MainDiv {
        position: relative;
        width: 90%;
        left: 5%;
    }
    .MainDiv>h1 {
        text-align: center;
    }

    .imgContainer {
        position: relative;
        height: 19em;
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .imgContainer>div {
        flex-direction: column;
        align-items: center;
    }

    .centerer1, .centerer2 {
        transform: translate(-12.5%, 0%);
    }

    .imgContainer>div>img {
        width: 10em;
        height: 30%;
    }

    .imgContainer>.centerer1>.img2 {
        position: relative;
        left: 25%;
    }

    .imgContainer>.centerer2>.img1, .imgContainer>.centerer2>.img3 {
        position: relative;
        left: 25%;
    }

    .imgContainerFive {
        display: none;
    }

    .imgContainerFivePhone {
        position: relative;
        height: 31.666em;
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .imgContainerFivePhone>div {
        flex-direction: column;
        align-items: center;
    }

    .imgContainerFivePhone>div>div {
        width: 10em;
        height: 18%;
    }

    .imgContainerFivePhone>.centerer1>.img1 {
        position: relative;
        left: 25%;
    }
}
