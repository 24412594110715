

.bodyDiv {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #fffbf3;
    overflow-x: hidden;
}

.MainDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    width: 100%;
}

.title {
    margin: 0px;
    margin-bottom: 0.5em;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 7em;
    width: 30em;

}

.input {
    position: relative;
    width: 100%;
    height: 3em;
    margin-bottom: 1em;
    border: none;
    border-bottom: solid;
    border-bottom-color: #48604f;
    background-color: #48604f3f;
    padding-left: 5px;
    font-family: "Inter";
    color: #0d3527;
    margin-bottom: 4em;
}

.input:focus {
    outline: none;
    border-bottom-color: #48604f;
}

.form>button {
    margin-top: 2em;
    transition: all 0.16s;
    border: none;
    width: 10em;
    height: 2.5em;
    background-color: #8CCC81;
    color: #FFFBF3;
    cursor: pointer;
    box-shadow: 4px 4px 2px 0px #0000003E;
    margin-bottom: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
}


.form>button:active {
    transform: translate(4px, 4px);
    box-shadow: 0px 0px 0px 0px #0000003E;
}

.error {
    color: rgb(217, 20, 20);
    font-size: 1em;
    margin-bottom: 1em;
    margin-top: 3em;
}