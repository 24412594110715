


.bodyDiv {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #fffbf3;
    overflow-x: hidden;
}

.MainDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    width: 100%;
}

.title {
    margin: 0px;
    margin-bottom: 0.5em;
}

.selector {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 7em;
    width: 60em;
    height: 30em;
}

.line {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 13em;
    margin-bottom: 1em;
    margin: 1em;
}

.button {
    border-radius: 20px;
    width: 45%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.16s;
    border: 2px solid #0d3527;
    box-shadow: 4px 4px 2px 0px #0000003E;
    font-size: 25px;
}

.button:active {
    transform: translate(4px, 4px);
    box-shadow: 0px 0px 0px 0px #0000003e;
}