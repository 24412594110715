.bodyDiv {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #fffbf3;
    overflow-x: hidden;
}

.MainHead > h1 {
    margin: 0px;
}

.MainHead {
    margin-top: 1em;
    margin-bottom: 4em;
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
}

.MainDiv > p {
    width: 85%;
    margin-bottom: 8em;
}

.lisianthus1 {
    display: none;
}

@media screen and (min-width: 1300px) {
    .MainDiv {
        position: relative;
        width: 70%;
        left: 15%;
    }

    .MainDiv > h2 {
        margin: 0px;
        margin-top: 1.5em;
        font-family: "Inter";
        color: #0d3527;
        font-size: 32px;
        font-weight: bold;
        margin-block-end: 1.5em;
    }

    .lisianthus1 {
        height: 750px;
    }
    @media screen and (min-width: 1500px) {
        .lisianthus1 {
            position: absolute;
            display: block;
            right: 7%;
            top: 20%;
        }
    }

    @media screen and (max-width: 1500px) and (min-width: 1300px){
        .lisianthus1 {
            position: absolute;
            display: block;
            right: 0%;
            top: 20%;
        }
    }
}

@media screen and (min-width: 900px) and (max-width: 1300px) {
    .MainDiv {
        position: relative;
        width: 80%;
        left: 10%;
    }

    .MainDiv > h2 {
        margin: 0px;
        margin-top: 1.5em;
        font-family: "Inter";
        color: #0d3527;
        font-size: 27px;
        font-weight: bold;
        margin-block-end: 1.5em;
        width: 90%;
    }

    @media screen and (min-width: 1100px) and (max-width: 1300px){
        .lisianthus1 {
            height: 600px;
            position: absolute;
            display: block;
            right: 0%;
            top: 20%;
        }
    }

    @media screen and (min-width: 900px) and (max-width: 1100px){
        .lisianthus1 {
            height: 600px;
            position: absolute;
            display: block;
            right: 0%;
            top: 20%;
        }
    }
}

@media screen and (min-width: 550px) and (max-width: 900px) {
    .MainDiv {
        position: relative;
        width: 90%;
        left: 5%;
    }

    .MainDiv > h2 {
        margin: 0px;
        margin-top: 1.5em;
        font-family: "Inter";
        color: #0d3527;
        font-size: 22px;
        font-weight: bold;
        margin-block-end: 1.5em;
        width: 90%;
    }

    @media screen and (min-width: 700px) and (max-width: 900px){
        .lisianthus1 {
            height: 500px;
            position: absolute;
            display: block;
            right: -4%;
            top: 20%;
        }
    }
}

@media screen and (min-width: 350px) and (max-width: 550px) {
    .MainDiv {
        position: relative;
        width: 90%;
        left: 5%;
    }
    .MainHead > h1 {
        text-align: center;
    }

    .MainDiv > h2 {
        margin: 0px;
        margin-top: 1.5em;
        font-family: "Inter";
        color: #0d3527;
        font-size: 20px;
        font-weight: bold;
        margin-block-end: 1.5em;
    }

    .MainDiv > p {
        text-align: justify;
        width: 100%;
    }
}

/* 200 - 350 */
@media screen and (min-width: 200px) and (max-width: 350px) {
    .MainDiv {
        position: relative;
        width: 90%;
        left: 5%;
    }

    .MainHead > h1 {
        text-align: center;
    }

    .MainDiv > h2 {
        margin: 0px;
        margin-top: 1.5em;
        font-family: "Inter";
        color: #0d3527;
        font-size: 18px;
        font-weight: bold;
        margin-block-end: 1.5em;
    }
    .MainDiv > p {
        text-align: justify;
        width: 100%;
    }
}
