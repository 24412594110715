body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-y: scroll;
    scroll-behavior: smooth;
    background-color: #fffbf3;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

@font-face {
    font-family: "customFont";
    src: url("./assets/CreamCake.otf") format("woff2");
}

@font-face {
    font-family: "Inter";
    src: url("./assets/Inter-Medium.ttf") format("woff2");
}

@media screen and (min-width: 1300px) {
    h1 {
        font-family: "customFont";
        color: #0d3527;
        font-size: 160px;
        font-weight: lighter;
    }

    p {
        font-family: "Inter";
        color: #0d3527;
        font-size: 20px;
        font-weight: lighter;
    }
}

@media screen and (min-width: 900px) and (max-width: 1300px) {
    h1 {
        font-family: "customFont";
        color: #0d3527;
        font-size: 140px;
        font-weight: lighter;
    }

    p {
        font-family: "Inter";
        color: #0d3527;
        font-size: 16px;
        font-weight: lighter;
    }
}

@media screen and (min-width: 550px) and (max-width: 900px) {
    h1 {
        font-family: "customFont";
        color: #0d3527;
        font-size: 100px;
        font-weight: lighter;
    }

    p {
      font-family: "Inter";
      color: #0d3527;
      font-size: 13px;
      font-weight: lighter;
  }
}

@media screen and (min-width: 350px) and (max-width: 550px) {
    h1 {
        font-family: "customFont";
        color: #0d3527;
        font-size: 85px;
        font-weight: lighter;
    }

    p {
      font-family: "Inter";
      color: #0d3527;
      font-size: 12px;
      font-weight: lighter;
  }
}

/* 200 - 350 */
@media screen and (min-width: 200px) and (max-width: 350px) {
    h1 {
        font-family: "customFont";
        color: #0d3527;
        font-size: 70px;
        font-weight: lighter;
    }

    p {
      font-family: "Inter";
      color: #0d3527;
      font-size: 11px;
      font-weight: lighter;
  }
}
