.bodyDiv {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #fffbf3;
    overflow-x: hidden;
}

.MainHead {
    position: relative;
    width: 100%;
}

.MainDiv > .sign {
    display: flex;
    justify-content: right;
}

.endText {
    position: relative;
    width: 95%;
    left: 2.5%;
}

.image {
    object-fit: cover;
}

.lisianthus1 {
    display: none;
}

.lisianthus2 {
    display: none;
}

/* 1300 - 1920 */
@media screen and (min-width: 1300px) {
    .lisianthus1 {
        height: 400px;
        position: absolute;
        display: block;
        left: 3%;
        top: 10%;
    }


    .lisianthus2 {
        height: 350px;
        position: absolute;
        display: block;
        right: 13%;
        bottom: 1%;
    }
    .text {
        width: 50%;
        margin-right: 5%;
        margin-left: 5%;
    }

    .MainDiv > .first {
        position: relative;
        width: 100%;
        margin-top: 4vw;
        display: flex;
    }

    .MainDiv > .second {
        position: relative;
        margin-top: 4vw;
        display: flex;
    }

    .MainDiv {
        position: relative;
        width: 70%;
        left: 15%;
    }

    .MainHead > h1 {
        text-align: end;
    }

    .image {
        position: relative;
        width: 20em;
        height: 20em;
        background-color: #48604f57;
        margin-right: 5%;
        margin-left: 5%;
    }

    .text > p {
        height: 100%;
        /* margin-block-end: 5em; */
        text-align: justify;
    }

    .MainDiv > p {
        height: 100%;
        margin-block-end: 5em;
        text-align: justify;
    }

    .MainDiv > .sign > h2 {
        font-weight: lighter;
        font-family: "customFont";
        color: #0d3527;
        height: 100%;
        font-size: 48px;
        margin-block-end: 0.5em;
    }

    .endHead > h2 {
        font-weight: normal;
        font-family: "customFont";
        color: #0d3527;
        font-size: 90px;
        margin-block-end: 0.5em;
        margin-block-start: 0px;
    }

    .endText > p {
        height: 100%;
        text-align: justify;
    }
}

/* 900 - 1300 */
@media screen and (min-width: 900px) and (max-width: 1300px) {

    .lisianthus1 {
        height: 250px;
        position: absolute;
        display: block;
        left: 0%;
        top: 13%;
    }

    .lisianthus2 {
        height: 230px;
        position: absolute;
        display: block;
        right: 6%;
        bottom: 2%;
    }

    .text {
        width: 50%;
        margin-right: 5%;
        margin-left: 5%;
    }

    .MainDiv > .first {
        position: relative;
        width: 100%;
        margin-top: 4vw;
        display: flex;
    }

    .MainDiv > .second {
        position: relative;
        margin-top: 4vw;
        display: flex;
    }

    .MainDiv {
        position: relative;
        width: 80%;
        left: 10%;
    }

    .MainHead > h1 {
        text-align: end;
    }

    .image {
        position: relative;
        width: 15em;
        height: 15em;
        background-color: #48604f57;
        margin-right: 5%;
        margin-left: 5%;
    }

    .MainDiv > p {
        height: 100%;
        margin-block-end: 5em;
        text-align: justify;
    }

    .text > p {
        height: 100%;
        text-align: justify;
    }

    .MainDiv > .sign > h2 {
        font-weight: lighter;
        font-family: "customFont";
        color: #0d3527;
        height: 100%;
        font-size: 48px;
        margin-block-end: 0.5em;
    }

    .endHead > h2 {
        font-weight: normal;
        font-family: "customFont";
        color: #0d3527;
        font-size: 70px;
        margin-block-end: 0.5em;
        margin-block-start: 0px;
    }

    .endText > p {
        height: 100%;
        text-align: justify;
    }
}

/* 550 - 950 */
@media screen and (min-width: 550px) and (max-width: 900px) {

    .lisianthus1 {
        height: 160px;
        position: absolute;
        display: block;
        left: 4%;
        top: 9%;
    }

    .lisianthus2 {
        height: 170px;
        position: absolute;
        display: block;
        right: 2%;
        bottom: 3%;
    }

    .text {
        width: 50%;
        margin-right: 5%;
        margin-left: 5%;
    }

    .MainDiv > .first {
        position: relative;
        width: 100%;
        margin-top: 4vw;
        display: flex;
    }

    .MainDiv > .second {
        position: relative;
        margin-top: 4vw;
        display: flex;
    }

    .MainDiv {
        position: relative;
        width: 90%;
        left: 5%;
    }

    .MainHead > h1 {
        text-align: end;
    }

    .image {
        position: relative;
        width: 10em;
        height: 10em;
        background-color: #48604f57;
        margin-right: 5%;
        margin-left: 5%;
    }

    .MainDiv > p {
        height: 100%;
        margin-block-end: 5em;
        text-align: justify;
    }

    .text > p {
        height: 100%;
        text-align: justify;
    }

    .MainDiv > .sign > h2 {
        font-weight: lighter;
        font-family: "customFont";
        color: #0d3527;
        height: 100%;
        font-size: 40px;
        margin-block-end: 0.5em;
    }

    .endHead > h2 {
        font-weight: normal;
        font-family: "customFont";
        color: #0d3527;
        font-size: 50px;
        margin-block-end: 0.5em;
        margin-block-start: 0px;
    }

    .endText > p {
        height: 100%;
        text-align: justify;
    }
}

/* 350 - 700 */
@media screen and (min-width: 350px) and (max-width: 550px) {

    @media screen and (min-width: 450px) and (max-width: 550px) {


        .lisianthus1 {
            height: 100px;
            position: absolute;
            display: block;
            left: 4%;
            top: 7%;
        }

        .lisianthus2 {
            height: 120px;
            position: absolute;
            display: block;
            right: 3%;
            bottom: 4%;
        }
    }

    @media screen and (min-width: 400px) and (max-width: 450px) {


        .lisianthus1 {
            height: 80px;
            position: absolute;
            display: block;
            left: 2%;
            top: 7%;
        }

        .lisianthus2 {
            height: 140px;
            position: absolute;
            display: block;
            right: 0%;
            bottom: 2%;
        }
    }
    .text {
        width: 50%;
        margin-right: 5%;
        margin-left: 5%;
        width: 100%;
    }

    .text > p {
        height: 100%;
        text-align: justify;
    }

    .MainDiv > .first {
        position: relative;
        width: 100%;
        margin-top: 4vw;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }

    .MainDiv > .second {
        position: relative;
        margin-top: 4vw;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .MainDiv {
        position: relative;
        width: 90%;
        left: 5%;
    }

    .image {
        position: relative;
        width: 13em;
        height: 13em;
        background-color: #48604f57;
        margin-right: 5%;
    }

    .MainHead > h1 {
        text-align: center;
    }

    .MainDiv > p {
        height: 100%;
        margin-block-end: 5em;
        text-align: justify;
    }

    .MainDiv > .sign > h2 {
        font-weight: lighter;
        font-family: "customFont";
        color: #0d3527;
        height: 100%;
        font-size: 27px;
        margin-block-end: 0.5em;
    }

    .endHead > h2 {
        font-weight: normal;
        font-family: "customFont";
        color: #0d3527;
        font-size: 42px;
        margin-block-end: 0.5em;
        margin-block-start: 0px;
    }

    .endText > p {
        height: 100%;
        text-align: justify;
    }
}

/* 200 - 350 */
@media screen and (min-width: 200px) and (max-width: 350px) {
    .text {
        width: 50%;
        margin-right: 5%;
        margin-left: 5%;
        width: 100%;
        margin-bottom: 2em;
    }

    .text > p {
        height: 100%;
        text-align: justify;
    }

    .MainDiv > .first {
        position: relative;
        width: 100%;
        margin-top: 4vw;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }

    .MainDiv > .second {
        position: relative;
        margin-top: 4vw;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .image {
        position: relative;
        width: 11em;
        height: 11em;
        background-color: #48604f57;
        margin-right: 5%;
        align-items: center;
        margin-bottom: 1em;
    }

    .MainDiv {
        position: relative;
        width: 90%;
        left: 5%;
    }

    .MainHead > h1 {
        text-align: center;
    }

    .MainDiv > p {
        height: 100%;
        margin-block-end: 5em;
        text-align: justify;
    }

    .MainDiv > .sign > h2 {
        font-weight: lighter;
        font-family: "customFont";
        color: #0d3527;
        height: 100%;
        font-size: 22px;
        margin-block-end: 0.5em;
    }

    .endHead > h2 {
        font-weight: normal;
        font-family: "customFont";
        color: #0d3527;
        font-size: 32px;
        margin-block-end: 0.5em;
        margin-block-start: 0px;
    }

    .endText > p {
        height: 100%;
        text-align: justify;
    }
}
