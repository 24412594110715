.bodyDiv {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #fffbf3;
    overflow-x: hidden;
}

.MainDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: 100vh;
    width: 70%;
    left: 15%;
}

.title {
    margin: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.titleSecond { 
    margin: 0px;
    margin-bottom: 0.5em;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.imgContainer {
    position: relative;
    width: 30%;
    margin: 1%;
    box-sizing: border-box;
    background-color: #48604f57;
    transition: all 0.2s;
    cursor: pointer;
    background-size: cover;
    background-position: center;
}

.imgContainer::before {
    content: "";
    display: block;
    padding-top: 100%;
}

.imgContainer>img {
    position: relative;
    /* width: 100%; */
    height: 100%;
    /* height: 100%; */

}

.catSelector {
    /* position: relative; */
    width: 50%;
    margin-top: 3em;
    height: 3em;
    border: none;
    border-bottom: solid;
    border-bottom-color: #48604f;
    background-color: #48604f3f;
    padding-left: 5px;
    font-family: "Inter";
    color: #0d3527;
    margin-bottom: 3em;
}

.catSelector:focus {
    outline: none;
    border-bottom-color: #48604f;
}


.grid {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    /* margin: -1%; */
    justify-content: center;
    margin-bottom: 10em;
    margin-top: 2em;
}

.deleteButton {
    position: fixed;
    right: 2em;
    bottom: 2em;
    width: 3.5em;
    height: 3.5em;
    border-radius: 10em;
    background-color: #8ccc81;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}