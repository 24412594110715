

.bodyDiv {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #fffbf3;
    overflow-x: hidden;
}

.MainDiv > h1 {
    width: 100%;
    text-align: end;
    margin-bottom: 0.5em;
}

p {
    text-align: justify;
}

.MainDiv > input {
    position: relative;
    left: 10%;
    margin-bottom: 1em;
    border: none;
    border-bottom: solid;
    border-bottom-color: #48604f;
    background-color: #48604f3f;
    padding-left: 5px;
    font-family: "Inter";
    color: #0d3527;
    font-size: 18px;
    margin-bottom: 2em;
    /* padding-top: 2px */
}

.MainDiv > input:focus {
    outline: none;
    border-bottom-color: #48604f;
}

.itemPicture {
    background-size: cover;
    background-position: center;
    box-shadow: 4px 4px 2px 0px #0000003e;
}

.MainDiv {
    margin-top: 4em;
}

.itemDiv {
    cursor: pointer;
}
@media screen and (min-width: 1300px) {
    .MainDiv {
        position: relative;
        width: 70%;
        left: 15%;
    }

    .MainDiv > input {
        width: 80%;

        height: 2.3em;
        margin-bottom: 7em;
    }

    .price {
        font-size: 1.5em;
    }

    .title {
        font-size: 2em;
    }

    .itemDiv {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-around;
        margin-bottom: 2em;
    }

    .itemPicture {
        width: 18vw;
        margin-right: 2em;
        background-color: #48604f57;
        height: 12vw;
    }

    .itemText {
        width: 70%;
        display: flex;
        flex-direction: column;
    }
}

@media screen and (min-width: 900px) and (max-width: 1300px) {
    .MainDiv {
        position: relative;
        width: 80%;
        left: 10%;
    }

    .MainDiv > input {
        width: 80%;
        height: 2.3em;
        margin-bottom: 6em;
    }

    .price {
        font-size: 1.5em;
    }

    .title {
        font-size: 2em;
    }

    .itemDiv {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-around;
        margin-bottom: 2em;
    }

    .itemPicture {
        width: 18vw;
        margin-right: 2em;
        background-color: #48604f57;
        height: 12vw;
    }

    .itemText {
        width: 70%;
        display: flex;
        flex-direction: column;
    }
}

@media screen and (min-width: 550px) and (max-width: 900px) {
    .MainDiv {
        position: relative;
        width: 90%;
        left: 5%;
    }

    .MainDiv > input {
        width: 80%;
        height: 2.2em;
        margin-bottom: 4em;
    }
    .price {
        font-size: 1.5em;
    }

    .title {
        font-size: 2em;
    }


    @media screen and (min-width: 750px) and (max-width: 900px) {
        .itemDiv {
            position: relative;
            width: 100%;
            display: flex;
            justify-content: space-around;
            margin-bottom: 2em;
        }

        .itemPicture {
            width: 18vw;
            margin-right: 2em;
            background-color: #48604f57;
            height: 12vw;
        }

        .itemText {
            width: 70%;
            display: flex;
            flex-direction: column;
        }
    }

    @media screen and (min-width: 550px) and (max-width: 750px) {
        .itemDiv {
            position: relative;
            width: 100%;
            display: flex;
            justify-content: space-around;
            margin-bottom: 2em;
        }

        .itemPicture {
            width: 22.5vw;
            margin-right: 2em;
            background-color: #48604f57;
            height: 15vw;
        }

        .itemText {
            width: 70%;
            display: flex;
            flex-direction: column;
        }
    }
}

@media screen and (min-width: 350px) and (max-width: 550px) {
    .MainDiv {
        position: relative;
        width: 90%;
        left: 5%;
    }
    .MainDiv > h1 {
        text-align: center;
    }

    .MainDiv > input {
        width: 90%;
        left: 5%;
        height: 2.5em;
        margin-bottom: 3em;
    }

    .description {
        max-height: 20vw;
        overflow-y: scroll;
        margin-bottom: 0px;
    }
    @media screen and (min-width: 450px) and (max-width: 550px) {
        .itemDiv {
            position: relative;
            width: 100%;
            display: flex;
            justify-content: space-around;
            margin-bottom: 0.7em;
            height: 21vw;
        }

        .itemPicture {
            width: 24vw;
            margin-right: 0.8em;
            background-color: #48604f57;
            height: 16vw;
        }

        .itemText {
            width: 60%;
            display: flex;
            flex-direction: column;
        }

        .price {
            font-size: 1.2em;
        }
    
        .title {
            font-size: 1.5em;
        }
    
    }

    @media screen and (min-width: 350px) and (max-width: 450px) {
        .itemDiv {
            position: relative;
            width: 100%;
            display: flex;
            justify-content: space-around;
            margin-bottom: 1em;
        }

        .itemPicture {
            width: 30vw;
            margin-right: 0.8em;
            background-color: #48604f57;
            height: 20vw;
        }

        .itemText {
            width: 50%;
            display: flex;
            flex-direction: column;
        }
        .price {
            font-size: 1em;
        }
    
        .title {
            font-size: 1.2em;
        }
    }
}

@media screen and (min-width: 200px) and (max-width: 450px) {
    .MainDiv {
        position: relative;
        width: 90%;
        left: 5%;
    }
    .MainDiv > h1 {
        text-align: center;
    }

    .price {
        font-size: 0.9em;
    }

    .title {
        font-size: 1.1em;
    }

    .MainDiv > input {
        width: 90%;
        left: 5%;
        height: 2.2em;
        margin-bottom: 2em;
    }
    .description {
        max-height: 20vw;
        overflow-y: scroll;
        margin-bottom: 0px;
    }

    .itemDiv {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-around;
        margin-bottom: 1em;
        height: 30vw;
    }

    .itemPicture {
        width: 36vw;
        margin-right: 0.5em;
        background-color: #48604f57;
        height: 24vw;
    }

    .itemText {
        width: 45%;
        display: flex;
        flex-direction: column;
    }
}
