

.bodyDiv {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #fffbf3;
    overflow-x: hidden;
}

.MainHead {
    position: relative;
    width: 100%;
}


.spacer>button {
    margin-top: 2em;
    transition: all 0.16s;
    border: none;
    background-color: #8CCC81;
    color: #FFFBF3;
    cursor: pointer;
    box-shadow: 4px 4px 2px 0px #0000003E;
}


.spacer>button:active {
    transform: translate(4px, 4px);
    box-shadow: 0px 0px 0px 0px #0000003E;
}

.spacer>button:focus {
    background-color: #8ccc81c6;
}

.spacer>button:hover {
    background-color: #8ccc81c6;
}


.MainDiv > div {
    align-items: center;
    justify-content: center;
    margin-bottom: 3em;
}

.lisianthus1 {
    display: none;
}


/* 1300 - 1920 */
@media screen and (min-width: 1300px) {

    .lisianthus1 {
        height: 400px;
        position: absolute;
        display: block;
        right: -2%;
        top: 66%;
    }

    .text {
        width: 50%;
        margin-right: 5%;
        margin-left: 5%;
    }

    .MainDiv > .first {
        position: relative;
        width: 100%;
        display: flex;
    }

    .MainDiv > .second {
        position: relative;
        display: flex;
    }

    .MainDiv {
        position: relative;
        width: 70%;
        left: 15%;
    }

    .MainHead > h1 {
        text-align: end;
    }

    .imgsContainer {
        position: relative;
        width: 30em;
        height: 25em;
    }

    .imgsContainer>img {
        position: absolute;
        height: 6em;
        width: 6em;
        background-color: #48604f57;
    }

    .text > p {
        height: 100%;
        /* margin-block-end: 5em; */
        text-align: justify;
    }

    .MainDiv > p {
        height: 100%;
        margin-block-end: 5em;
        text-align: justify;
    }

    .spacer>button {
        width: 15em;
        height: 3em;
        font-size: 20px;
    }
}

/* 900 - 1300 */
@media screen and (min-width: 900px) and (max-width: 1300px) {

    .lisianthus1 {
        height: 270px;
        position: absolute;
        display: block;
        right: -3%;
        top: 66%;
    }


    .text {
        width: 50%;
        margin-right: 5%;
        margin-left: 5%;
    }

    .MainDiv > .first {
        position: relative;
        width: 100%;
        display: flex;
    }

    .MainDiv > .second {
        position: relative;
        display: flex;
    }

    .MainDiv {
        position: relative;
        width: 80%;
        left: 10%;
    }

    .MainHead > h1 {
        text-align: end;
    }

    .imgsContainer {
        position: relative;
        width: 20em;
        height: 18em;
    }

    .imgsContainer>img {
        position: absolute;
        height: 4.4em;
        width: 4.4em;
        background-color: #48604f57;
    }

    .MainDiv > p {
        height: 100%;
        margin-block-end: 5em;
        text-align: justify;
    }

    .text > p {
        height: 100%;
        text-align: justify;
    }

    .spacer>button {
        width: 15em;
        height: 2.8em;
        font-size: 18px;
    }
}

/* 550 - 950 */
@media screen and (min-width: 550px) and (max-width: 900px) {

    @media screen and (min-width: 750px) and (max-width: 900px) {
        .lisianthus1 {
            height: 150px;
            position: absolute;
            display: block;
            right: -3%;
            top: 66%;
        }
    }

    .text {
        width: 50%;
        margin-right: 5%;
        margin-left: 5%;
    }

    .MainDiv > .first {
        position: relative;
        width: 100%;
        display: flex;
    }

    .MainDiv > .second {
        position: relative;
        display: flex;
    }

    .MainDiv {
        position: relative;
        width: 90%;
        left: 5%;
    }

    .MainHead > h1 {
        text-align: end;
    }

    .imgsContainer {
        position: relative;
        width: 17em;
        height: 15em;
    }

    .imgsContainer>img {
        position: absolute;
        height: 3.4em;
        width: 3.4em;
        background-color: #48604f57;
    }

    .MainDiv > p {
        height: 100%;
        margin-block-end: 5em;
        text-align: justify;
    }

    .text > p {
        height: 100%;
        text-align: justify;
    }

    .spacer>button {
        width: 14em;
        height: 2.4em;
        font-size: 13px;
    }
}

/* 350 - 700 */
@media screen and (min-width: 350px) and (max-width: 550px) {
    .text {
        width: 50%;
        margin-right: 5%;
        margin-left: 5%;
        width: 100%;
    }

    .text > p {
        height: 100%;
        text-align: justify;
    }

    .MainDiv > .first {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }

    .MainDiv > .second {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .MainDiv {
        position: relative;
        width: 90%;
        left: 5%;
    }

    .imgsContainer {
        position: relative;
        width: 19em;
        height: 17em;
    }

    .imgsContainer>img {
        position: absolute;
        height: 4em;
        width: 4em;
        background-color: #48604f57;
    }

    .MainHead > h1 {
        text-align: center;
    }

    .MainDiv > p {
        height: 100%;
        margin-block-end: 5em;
        text-align: justify;
    }


    .spacer {
        margin-top: 2em;
        margin-bottom: 3em;
    }

    .spacer>button {
        width: 14em;
        height: 2.5em;
        font-size: 12px;
    }
}

/* 200 - 350 */
@media screen and (min-width: 200px) and (max-width: 350px) {
    .text {
        width: 50%;
        margin-right: 5%;
        margin-left: 5%;
        width: 100%;
        margin-bottom: 2em;
    }

    .text > p {
        height: 100%;
        text-align: justify;
    }

    .MainDiv > .first {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }

    .MainDiv > .second {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    
    .imgsContainer {
        position: relative;
        width: 16.5em;
        height: 15.5em;
    }

    .imgsContainer>img {
        position: absolute;
        height: 3.5em;
        width: 3.5em;
        background-color: #48604f57;
    }

    .MainDiv {
        position: relative;
        width: 90%;
        left: 5%;
    }

    .MainHead > h1 {
        text-align: center;
    }

    .MainDiv > p {
        height: 100%;
        margin-block-end: 5em;
        text-align: justify;
    }


    .spacer {
        margin-top: 1em;
        margin-bottom: 2em;
    }

    .spacer>button {
        width: 14em;
        height: 2.5em;
        font-size: 12px;
    }   
}

