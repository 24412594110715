

.bodyDiv {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #fffbf3;
    overflow-x: hidden;
}

.MainHead {
    position: relative;
    width: 100%;
}

.MainDiv > .sign {
    display: flex;
    justify-content: right;
}

.endText {
    position: relative;
    width: 95%;
    left: 2.5%;

}


.spacer>button {
    margin-top: 2em;
    transition: all 0.16s;
    border: none;
    background-color: #8CCC81;
    color: #FFFBF3;
    cursor: pointer;
    box-shadow: 4px 4px 2px 0px #0000003E;
}


.spacer>button:active {
    transform: translate(4px, 4px);
    box-shadow: 0px 0px 0px 0px #0000003E;
}

.spacer>button:focus {
    background-color: #8ccc81c6;
}

.spacer>button:hover {
    background-color: #8ccc81c6;
}


.MainDiv > div {
    align-items: center;
    justify-content: center;
    margin-bottom: 3em;
}

.image {
    object-fit: cover;
}

.lisianthus1 {
    display: none;
}


/* 1300 - 1920 */
@media screen and (min-width: 1300px) {


    @media screen and (min-width: 1500px) {

        .lisianthus1 {
            height: 400px;
            position: absolute;
            display: block;
            left: -2%;
            top: 30%;
        }   
    }
    

    @media screen and (min-width: 1300px) and (max-width: 1500px) {
        .lisianthus1 {
            height: 400px;
            position: absolute;
            display: block;
            left: -5%;
            top: 30%;
        }   
    }
    

    .text {
        width: 50%;
        margin-right: 5%;
        margin-left: 5%;
    }

    .MainDiv > .first {
        position: relative;
        width: 100%;
        display: flex;
    }

    .MainDiv > .second {
        position: relative;
        display: flex;
    }

    .MainDiv {
        position: relative;
        width: 70%;
        left: 15%;
    }

    .MainHead > h1 {
        text-align: end;
    }

    .image {
        position: relative;
        width: 20em;
        height: 20em;
        background-color: #48604f57;
        margin-right: 5%;
        margin-left: 5%;
    }

    .text > p {
        height: 100%;
        /* margin-block-end: 5em; */
        text-align: justify;
    }

    .MainDiv > p {
        height: 100%;
        margin-block-end: 5em;
        text-align: justify;
    }

    .MainDiv > .sign > h2 {
        font-weight: lighter;
        font-family: "customFont";
        color: #0d3527;
        height: 100%;
        font-size: 48px;
        margin-block-end: 0.5em;
    }

    .endHead > h2 {
        font-weight: normal;
        font-family: "customFont";
        color: #0d3527;
        font-size: 90px;
        margin-block-end: 0.5em;
        margin-block-start: 0px;
    }

    .endText>p {
        height: 100%;
        text-align: justify;
    }

    .spacer>button {
        width: 15em;
        height: 3em;
        font-size: 20px;
    }
}

/* 900 - 1300 */
@media screen and (min-width: 900px) and (max-width: 1300px) {


    @media screen and (min-width: 1100px) and (max-width: 1300px) {
        .lisianthus1 {
            height: 250px;
            position: absolute;
            display: block;
            left:-2.5%;
            top: 30%;
        }   
    }

    @media screen and (min-width: 900px) and (max-width: 1100px) {
        .lisianthus1 {
            height: 180px;
            position: absolute;
            display: block;
            left:-2.5%;
            top: 30%;
        }   
    }


    .text {
        width: 50%;
        margin-right: 5%;
        margin-left: 5%;
    }

    .MainDiv > .first {
        position: relative;
        width: 100%;
        display: flex;
    }

    .MainDiv > .second {
        position: relative;
        display: flex;
    }

    .MainDiv {
        position: relative;
        width: 80%;
        left: 10%;
    }

    .MainHead > h1 {
        text-align: end;
    }

    .image {
        position: relative;
        width: 15em;
        height: 15em;
        background-color: #48604f57;
        margin-right: 5%;
        margin-left: 5%;
    }

    .MainDiv > p {
        height: 100%;
        margin-block-end: 5em;
        text-align: justify;
    }

    .text > p {
        height: 100%;
        text-align: justify;
    }

    .MainDiv > .sign > h2 {
        font-weight: lighter;
        font-family: "customFont";
        color: #0d3527;
        height: 100%;
        font-size: 48px;
        margin-block-end: 0.5em;
    }

    .endHead > h2 {
        font-weight: normal;
        font-family: "customFont";
        color: #0d3527;
        font-size: 70px;
        margin-block-end: 0.5em;
        margin-block-start: 0px;
    }

    .endText>p {
        height: 100%;
        text-align: justify;
    }

    .spacer>button {
        width: 15em;
        height: 2.8em;
        font-size: 18px;
    }
}

/* 550 - 950 */
@media screen and (min-width: 550px) and (max-width: 900px) {

    @media screen and (min-width: 700px) and (max-width: 900px) {
        .lisianthus1 {
            height: 110px;
            position: absolute;
            display: block;
            left:-1.5%;
            top: 30%;
        }   
    }

    .text {
        width: 50%;
        margin-right: 5%;
        margin-left: 5%;
    }

    .MainDiv > .first {
        position: relative;
        width: 100%;
        display: flex;
    }

    .MainDiv > .second {
        position: relative;
        display: flex;
    }

    .MainDiv {
        position: relative;
        width: 90%;
        left: 5%;
    }

    .MainHead > h1 {
        text-align: end;
    }

    .image {
        position: relative;
        width: 10em;
        height: 10em;
        background-color: #48604f57;
        margin-right: 5%;
        margin-left: 5%;
    }

    .MainDiv > p {
        height: 100%;
        margin-block-end: 5em;
        text-align: justify;
    }

    .text > p {
        height: 100%;
        text-align: justify;
    }

    .MainDiv > .sign > h2 {
        font-weight: lighter;
        font-family: "customFont";
        color: #0d3527;
        height: 100%;
        font-size: 40px;
        margin-block-end: 0.5em;
    }

    .endHead > h2 {
        font-weight: normal;
        font-family: "customFont";
        color: #0d3527;
        font-size: 50px;
        margin-block-end: 0.5em;
        margin-block-start: 0px;
    }

    .endText>p {
        height: 100%;
        text-align: justify;
    }

    .spacer>button {
        width: 14em;
        height: 2.4em;
        font-size: 13px;
    }
}

/* 350 - 700 */
@media screen and (min-width: 350px) and (max-width: 550px) {
    .text {
        width: 50%;
        margin-right: 5%;
        margin-left: 5%;
        width: 100%;
    }

    .text > p {
        height: 100%;
        text-align: justify;
    }

    .MainDiv > .first {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }

    .MainDiv > .second {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .MainDiv {
        position: relative;
        width: 90%;
        left: 5%;
    }

    .image {
        position: relative;
        width: 13em;
        height: 13em;
        background-color: #48604f57;
        margin-right: 5%;
    }

    .MainHead > h1 {
        text-align: center;
    }

    .MainDiv > p {
        height: 100%;
        margin-block-end: 5em;
        text-align: justify;
    }

    .MainDiv > .sign > h2 {
        font-weight: lighter;
        font-family: "customFont";
        color: #0d3527;
        height: 100%;
        font-size: 27px;
        margin-block-end: 0.5em;
    }

    .endHead > h2 {
        font-weight: normal;
        font-family: "customFont";
        color: #0d3527;
        font-size: 42px;
        margin-block-end: 0.5em;
        margin-block-start: 0px;
    }

    

    .endText>p {
        height: 100%;
        text-align: justify;
    }

    .spacer {
        margin-top: 2em;
        margin-bottom: 3em;
    }

    .spacer>button {
        width: 14em;
        height: 2.5em;
        font-size: 12px;
    }
}

/* 200 - 350 */
@media screen and (min-width: 200px) and (max-width: 350px) {
    .text {
        width: 50%;
        margin-right: 5%;
        margin-left: 5%;
        width: 100%;
        margin-bottom: 2em;
    }

    .text > p {
        height: 100%;
        text-align: justify;
    }

    .MainDiv > .first {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }

    .MainDiv > .second {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .image {
        position: relative;
        width: 11em;
        height: 11em;
        background-color: #48604f57;
        margin-right: 5%;
        align-items: center;
        margin-bottom: 1em;
    }

    .MainDiv {
        position: relative;
        width: 90%;
        left: 5%;
    }

    .MainHead > h1 {
        text-align: center;
    }

    .MainDiv > p {
        height: 100%;
        margin-block-end: 5em;
        text-align: justify;
    }

    .MainDiv > .sign > h2 {
        font-weight: lighter;
        font-family: "customFont";
        color: #0d3527;
        height: 100%;
        font-size: 22px;
        margin-block-end: 0.5em;
    }

    .endHead > h2 {
        font-weight: normal;
        font-family: "customFont";
        color: #0d3527;
        font-size: 32px;
        margin-block-end: 0.5em;
        margin-block-start: 0px;
    }


    .endText>p {
        height: 100%;
        text-align: justify;
    }

    .spacer {
        margin-top: 1em;
        margin-bottom: 2em;
    }

    .spacer>button {
        width: 14em;
        height: 2.5em;
        font-size: 12px;
    }   
}

