

.bodyDiv {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #FFFBF3;
    overflow-x: hidden;
}

.UploadMainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
}

.UploadContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    min-height: 70%;
}

.form {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.submit {
    margin-top: 2em;
    transition: all 0.16s;
    border: none;
    background-color: #8ccc81;
    padding: 10px 20px;
    width: 8em;
    color: #fff;
    cursor: pointer;
    box-shadow: 2px 3px 3px 0px #0000003e;
    cursor: pointer;
}
.submit:active {
    transform: translate(2px, 3px);
    box-shadow: 0px 0px 0px 0px #0000003e;
}

.imagesPreview {
    position: absolute;
    right: 0px;
    top: 25%;
    height: 50%;
    border-top: 2px solid #555;
    border-left: 2px solid #555;
    border-bottom: 2px solid #555;
    width: 250px;
    border-radius: 10px 0px 0px 10px;   
    overflow-x: scroll;
}

.singleImgContainer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
}

.removeImg {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.162);
    transform: translate( -105px, -35px);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.title {
    margin: 0px;
    margin-bottom: 0.5em;
}

.drop_container {
    position: relative;
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 200px;
    padding: 20px;
    border-radius: 10px;
    border: 2px dashed #555;
    color: #444;
    cursor: pointer;
    transition: background 0.2s ease-in-out, border 0.2s ease-in-out;
}

.description {
    position: relative;
    width: calc(100% - 10px);
    height: 10em;
    /* margin-bottom: 1em; */
    border: none;
    border-bottom: solid;
    border-bottom-color: #48604f;
    background-color: #48604f3f;

    padding: 5px;
    font-family: "Inter";
    color: #0d3527;
    /* font-size: 15px; */
    resize: none;
}

.description:focus {
    outline: none;
    border-bottom-color: #48604f;
}

.drop_container.drag_active {
    background: #eee;
    border-color: #111;
}

.drop_container.drag_active .drop-title {
    color: #222;
}

.drop_container:hover {
    background: #eee;
    border-color: #111;
}

.drop_container:hover .drop_title {
    color: #222;
}

.drop_title {
    color: #444;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    transition: color 0.2s ease-in-out;
}

.inputFile[type="file"] {
    width: 350px;
    max-width: 100%;
    color: #444;
    padding: 5px;
    background: #fff;
    border-radius: 10px;
    border: 1px solid #555;
}

.inputFile[type="file"]::file-selector-button {
    margin-right: 20px;
    border: none;
    background: #8ccc81;
    padding: 10px 20px;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    transition: background 0.2s ease-in-out;
}

.inputFile[type="file"]::file-selector-button:hover {
    background: #8ccc81;
}

input {
    position: relative;
    width: 100%;
    margin-top: 3em;
    height: 3em;
    border: none;
    border-bottom: solid;
    border-bottom-color: #48604f;
    background-color: #48604f3f;
    padding-left: 5px;
    font-family: "Inter";
    color: #0d3527;
    margin-bottom: 2em;
}

input:focus {
    outline: none;
    border-bottom-color: #48604f;
}


textarea {
    position: relative;
    width: 100%;
    height: 10em;
    margin-bottom: 1em;
    border: none;
    border-bottom: solid;
    border-bottom-color: #48604f;
    background-color: #48604f3f;

    padding: 5px;
    font-family: "Inter";
    color: #0d3527;
    /* font-size: 15px; */
    resize: none;
}

textarea:focus {
    outline: none;
    border-bottom-color: #48604f;
}