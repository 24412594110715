/* @media screen and (max-width: 330px) and (min-width: 0px) {

    .ContactButton {
        display: flex;
        justify-content: center;
        margin-top: 50px;
        padding-bottom: 50px;
    }
    
    .ContactButton>button {
        border: none;
        font-family: 'Inter';
        background-color: #8CCC81;
        border-radius: 70%;
        color: #FFFBF3;
        font-size: 0.8em;
        border : none;
        padding-right: 40px;
        padding-left: 40px;
        padding-top: 30px;
        padding-bottom: 30px;
        box-shadow: 0 8px 4px -4px #0000003E;
        cursor: pointer;
    }

}


@media screen and (max-width: 450px) and (min-width: 330px) {

    .ContactButton {
        display: flex;
        justify-content: center;
        margin-top: 50px;
        padding-bottom: 50px;
    }
    
    .ContactButton>button {
        border: none;
        font-family: 'Inter';
        background-color: #8CCC81;
        border-radius: 70%;
        color: #FFFBF3;
        font-size: 1em;
        padding-right: 40px;
        padding-left: 40px;
        padding-top: 30px;
        padding-bottom: 30px;
        box-shadow: 0 8px 4px -4px #0000003E;
        cursor: pointer;
    }
}


@media screen and (max-width: 1300px) and (min-width: 450px) {

    .ContactButton {
        display: flex;
        justify-content: center;
        margin-top: 50px;
        padding-bottom: 50px;
    }
    
    .ContactButton>button {
        border: none;
        font-family: 'Inter';
        background-color: #8CCC81;
        border-radius: 70%;
        color: #FFFBF3;
        font-size: 1.4em;
        border : none;
        padding-right: 40px;
        padding-left: 40px;
        padding-top: 30px;
        padding-bottom: 30px;
        box-shadow: 0 8px 4px -4px #0000003E;
        cursor: pointer;
    }



}
 */

@media screen and (min-width: 1000px) {
    .container {
        width: 100%;
        height: 12vw;
        display: flex;
        justify-content: center;
        margin-top: 8em;
        margin-bottom: 5em;

    }

    .ContactButton {
        width: 20vw;
        height: 7vw;
    }

    .ContactButton > button {
        transition: all 0.16s ease 0s;
        font-family: "Inter";
        background-color: #8ccc81;
        font-weight: bold;
        border-radius: 70%;
        color: #fffbf3;
        font-size: 1.9vw;
        width: 100%;
        height: 100%;
        border: none;
        box-shadow: 4px 8px 3px 0px #0000003e;
        cursor: pointer;
    }

    .ContactButton > button:active {
        font-family: "Inter";
        font-weight: bold;
        border-radius: 70%;
        color: #fffbf3;
        border: none;
        padding-right: 40px;
        padding-left: 40px;
        padding-top: 30px;
        padding-bottom: 30px;
        box-shadow: 0px 0px 0px 0px #0000003e;
        cursor: pointer;
        transform: translate(4px, 8px);
    }
}

@media screen and (max-width: 1000px) and (min-width: 600px) {
    .container {
        width: 100%;
        height: 12vw;
        display: flex;
        justify-content: center;
        margin-top: 8em;
        margin-bottom: 5em;

    }

    .ContactButton {
        width: 32vw;
        height: 11vw;
    }

    .ContactButton > button {
        transition: all 0.16s ease 0s;
        font-family: "Inter";
        background-color: #8ccc81;
        font-weight: bold;
        border-radius: 70%;
        color: #fffbf3;
        font-size: 2.6vw;
        width: 100%;
        height: 100%;
        border: none;
        box-shadow: 4px 8px 3px 0px #0000003e;
        cursor: pointer;
    }

    .ContactButton > button:active {
        font-family: "Inter";
        font-weight: bold;
        border-radius: 70%;
        color: #fffbf3;
        border: none;
        padding-right: 40px;
        padding-left: 40px;
        padding-top: 30px;
        padding-bottom: 30px;
        box-shadow: 0px 0px 0px 0px #0000003e;
        cursor: pointer;
        transform: translate(4px, 8px);
    }
}

@media screen and (max-width: 600px) and (min-width: 200px) {
    .container {
        margin-top: 3em;
        width: 100%;
        height: 20vw;
        display: flex;
        justify-content: center;
        margin-bottom: 3em;

    }

    .ContactButton > button {
        transition: all 0.16s ease 0s;
        font-family: "Inter";
        background-color: #8ccc81;
        font-weight: bold;
        border-radius: 70%;
        color: #fffbf3;
        font-size: 3.5vw;
        width: 40vw;
        height: 14vw;
        border: none;
        box-shadow: 3px 6px 3px 0px #0000003e;
        cursor: pointer;
    }

    .ContactButton > button:active {
        font-family: "Inter";
        font-weight: bold;
        border-radius: 70%;
        color: #fffbf3;
        border: none;
        box-shadow: 0px 0px 0px 0px #0000003e;
        cursor: pointer;
        transform: translate(3px, 6px);
    }
}

@media screen and (max-width: 400px) and (min-width: 200px) {
    .container {
        width: 100%;
        height: 23vw;
        display: flex;
        justify-content: center;
        margin-top: 3em;
        margin-bottom: 2em;
    }

    .ContactButton > button {
        transition: all 0.16s ease 0s;
        font-family: "Inter";
        background-color: #8ccc81;
        font-weight: bold;
        border-radius: 70%;
        color: #fffbf3;
        font-size: 4.3vw;
        width: 50vw;
        height: 17vw;
        border: none;
        box-shadow: 2px 4px 3px 0px #0000003e;
        cursor: pointer;
    }

    .ContactButton > button:active {
        font-family: "Inter";
        font-weight: bold;
        border-radius: 70%;
        color: #fffbf3;
        border: none;
        box-shadow: 0px 0px 0px 0px #0000003e;
        cursor: pointer;
        transform: translate(2px, 4px);
    }
}
