@font-face {
    font-family: 'Inter';
    src: url('./../assets/Inter-Medium.ttf') format('woff2');
}


@media screen and (min-width: 1201px) {
    .checkbox {
        display: none;
    }

    .navbar {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .navbar_nav {
        padding: 0px;
        padding-bottom: 10px;
        justify-content: center;
        align-items: center;
        display: flex;
        border-bottom: solid;
        border-bottom-color: #0D3527;
        box-shadow: 0 6px 4px -4px #0000003E
    }

    .nav_item_logo {
        list-style-type: none;
    }

    .nav_item {
        list-style-type: none;
        /* width: 2vw; */
    }

    .items {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .nav_link {
        margin-left: 2vw;
        margin-right: 2vw;
        border: solid;
        border-width: 2px;
        border-color: #0D3527;
        color: #0D3527;
        background-color: #00000000;
        padding-left: 35px;
        padding-right: 35px;
        padding-top: 8px;
        padding-bottom: 8px;
        font-family: 'Inter', sans-serif;
        font-size: 0.76vw;
        cursor: pointer;
    }

    .nav_green {
        margin-left: 2rem;
        border: solid;
        border-color: #8CCC81;
        color: #F9FFF3;
        background-color: #8CCC81;
        padding-left: 35px;
        padding-right: 35px;
        padding-top: 8px;
        padding-bottom: 8px;
        font-family: 'Inter', sans-serif;
        font-size: 0.76vw;
        cursor: pointer;
    }
}

@media screen and (max-width: 1200px) {
    /* body {
        overflow: hidden;
    } */
    .navbar {
        /* justify-content: center; */
        width: 100%;
        align-items: center;
    }

    .navbar_nav {
        position: relative;
        padding: 0px;
        padding-bottom: 10px;
        width: 100%;
        align-items: center;


        display: flex;
        border-bottom: solid;
        border-bottom-color: #FFFBF3;
        box-shadow: 0 6px 4px -4px #0000003E
    }


    .nav_item_logo {
        list-style-type: none;
        margin-left: 2em;
    }

    .checkbox {
        position: absolute;
        display: block;
        height: 48px;
        width: 48px;
        right: 2em;
        z-index: 5;
        opacity: 0;
        cursor: pointer;
    }

    .navbar_nav input[type="checkbox"]:not(:checked)~.items {
        transform:translateX(100%);
    }

    .navbar_nav input[type="checkbox"]:checked~.items {
        
        transform:translateX(-100%);
    }

    .hamburger_lines {
        display: block;
        height: 32px;
        width: 40px;
        position: absolute;
        /* top: 17px; */
        right: 2em;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .hamburger_lines .line1 {
        display: block;
        height: 4px;
        width: 100%;
        border-radius: 10px;
        background: #8CCC81;
        transform-origin: 0% 0%;
        transition: transform 0.4s ease-in-out;
    }

    .hamburger_lines .line2 {
        display: block;
        height: 4px;
        width: 100%;
        border-radius: 10px;
        background: #8CCC81;
        transition: transform 0.2s ease-in-out;
    }

    .hamburger_lines .line3 {
        display: block;
        height: 4px;
        width: 100%;
        border-radius: 10px;
        background: #8CCC81;
        transform-origin: 0% 100%;
        transition: transform 0.4s ease-in-out;
    }



    .navbar_nav input[type="checkbox"]:checked~.hamburger_lines .line1 {
        transform: rotate(45deg);
    }

    .navbar_nav input[type="checkbox"]:checked~.hamburger_lines .line2 {
        transform: scaleY(0);
    }

    .navbar_nav input[type="checkbox"]:checked~.hamburger_lines .line3 {
        transform: rotate(-45deg);
    }

    .items {
        display: flex;
        transition: transform 0.3s ease-in-out;
        flex-direction: column;
        align-items: center;
        z-index: 4;
        background-color: #FFFBF3;
        position: absolute;
        top: 56px;
        left: 100%;
        width: 250px;
        padding-top: 2em;
        padding-bottom: 2em;
        box-shadow: -2px 4px 3px 0px #0000003E;
    }


    .nav_item {
        width: 80%;
        list-style-type: none;
    }

    .nav_link {
        position: relative;
        width: 100%;
        border: solid;
        border-color: #0D3527;
        color: #0D3527;
        background-color: #00000000;
        margin-bottom: 1em;
        padding-top: 8px;
        padding-bottom: 8px;
        font-family: 'Inter', sans-serif;
        cursor: pointer;
    }


    .nav_green {
        position: relative;
        width: 100%;

        border: solid;
        border-color: #8CCC81;
        color: #FFFBF3;
        background-color: #8CCC81;
        padding-top: 8px;
        padding-bottom: 8px;
        font-family: 'Inter', sans-serif;
        cursor: pointer;
    }

}












.nav_logo_link {
    margin-right: 2rem;
    border: none;
    background-color: #00000000;
    filter: invert(14%) sepia(15%) saturate(2187%) hue-rotate(108deg) brightness(97%) contrast(91%);
    cursor: pointer;
}


.nav_logo {
    width: 100px;
    margin-right: 1rem;
}

