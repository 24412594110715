.bodyDiv {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #fffbf3;
    overflow-x: hidden;
}

.MainDiv {
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.MainDiv > button {
    margin-top: 2em;
    transition: all 0.16s;
    border: none;
    background-color: #8ccc81;
    color: #fffbf3;
    cursor: pointer;
    box-shadow: 4px 4px 2px 0px #0000003e;
}

.MainDiv > button:active {
    transform: translate(4px, 4px);
    box-shadow: 0px 0px 0px 0px #0000003e;
}

.MainDiv > button:focus {
    background-color: #8ccc81c6;
}

.MainDiv > button:hover {
    background-color: #8ccc81c6;
}

@media screen and (min-width: 1300px) {
    .MainDiv {
        position: relative;
        width: 70%;
        left: 15%;
    }

    .MainDiv > p {
        margin: 0px;
        margin-top: 1.5em;
        font-family: "Inter";
        color: #0d3527;
        font-size: 32px;
        font-weight: bold;
        margin-block-end: 1.5em;
    }
    .MainDiv > button {
        width: 12em;
        height: 3em;
        font-size: 20px;
    }
}

@media screen and (min-width: 900px) and (max-width: 1300px) {
    .MainDiv {
        position: relative;
        width: 80%;
        left: 10%;
    }

    .MainDiv > p {
        margin: 0px;
        margin-top: 1.5em;
        font-family: "Inter";
        color: #0d3527;
        font-size: 27px;
        font-weight: bold;
        margin-block-end: 1.5em;
    }

    .MainDiv > button {
        width: 11em;
        height: 2.8em;
        font-size: 18px;
    }
}

@media screen and (min-width: 550px) and (max-width: 900px) {
    .MainDiv {
        position: relative;
        width: 90%;
        left: 5%;
    }

    .MainDiv > p {
        margin: 0px;
        margin-top: 1.5em;
        font-family: "Inter";
        color: #0d3527;
        font-size: 22px;
        font-weight: bold;
        margin-block-end: 1.5em;
    }

    .MainDiv > button {
        width: 11em;
        height: 3em;
        font-size: 13px;
    }
}

@media screen and (min-width: 350px) and (max-width: 550px) {
    .MainDiv {
        position: relative;
        width: 90%;
        left: 5%;
    }
    .MainDiv > p {
        margin: 0px;
        margin-top: 1.5em;
        font-family: "Inter";
        color: #0d3527;
        font-size: 20px;
        font-weight: bold;
        margin-block-end: 1.5em;
    }

    .MainDiv > button {
        width: 10em;
        height: 3em;
        font-size: 12px;
    }
}

/* 200 - 350 */
@media screen and (min-width: 200px) and (max-width: 350px) {
    .MainDiv {
        position: relative;
        width: 90%;
        left: 5%;
    }

    .MainDiv > p {
        margin: 0px;
        margin-top: 1.5em;
        font-family: "Inter";
        color: #0d3527;
        font-size: 18px;
        font-weight: bold;
        margin-block-end: 1.5em;
    }

    .MainDiv > button {
        width: 10em;
        height: 3em;
        font-size: 11px;
    }
}
