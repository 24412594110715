

.bodyDiv {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #fffbf3;
    overflow-x: hidden;
}

.MainHead {
    position: relative;
    width: 100%;
}

.MainHead > h1 {
    margin: 0px;
    text-align: end;
}

.text {
    margin-top: 3em;
    position: relative;
    display: flex;
    width: 60%;
    left: 25%;
    justify-content: center;
}

.text > p {
    height: 100%;
    margin-block-end: 5em;
}

.contacts {
    position: relative;
    width: 60%;
    left: 25%;
    display: flex;
    margin-bottom: 5em;
}

.contacts > .infos > .data > h4 {
    display: flex;
    width: 100%;
    align-items: center;
    font-family: "Inter";
    color: #0d3527;
    /* font-size: 20px; */
    font-weight: lighter;
    height: 20%;
    margin: 0em;
}

.contacts > .infos > .icons > div {
    position: relative;
    height: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contacts > .infos {
    width: 100%;
    display: flex;
    height: 34vh;
}

.contacts > .infos > .icons {
    width:25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contacts > .infos > .data {
    width: 75%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.map {
    position: relative;
    display: flex;
    width: 60%;
    left: 25%;
}

.map>.mapimg>iframe {
    border: none;
    width: 100%;
    box-shadow: 10px 10px 0px 0px #0000003E;
}

.form {
    margin-top: 5em;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5em;
}


.form>div {
    width: 60%;
}

.form>button {
    margin-top: 2em;
    transition: all 0.16s;
    border: none;
    background-color: #8CCC81;
    color: #FFFBF3;
    cursor: pointer;
    box-shadow: 4px 4px 2px 0px #0000003E;
}


.form>button:active {
    transform: translate(4px, 4px);
    box-shadow: 0px 0px 0px 0px #0000003E;
}

.form>button:focus {
    background-color: #8ccc81c6;
}

.form>button:hover {
    background-color: #8ccc81c6;
}



.form>div>input {
    position: relative;
    width: 100%;
    height: 2em;
    margin-bottom: 2em;
    border: none;
    border-bottom: solid;
    border-bottom-color: #48604f;
    background-color: #48604f3f;
    padding-left: 5px;
    font-family: "Inter";
    color: #0d3527;
    margin-top: 0em;
    /* font-size: 15px; */
}

.form>div>input:focus {
    outline: none;
    border-bottom-color: #48604f;
}

.form>div>textarea {
    position: relative;
    width: 100%;
    height: 10em;
    margin-bottom: 1em;
    border: none;
    border-bottom: solid;
    border-bottom-color: #48604f;
    background-color: #48604f3f;

    padding: 5px;
    font-family: "Inter";
    color: #0d3527;
    /* font-size: 15px; */
    resize: none;
}

.form>div>textarea:focus {
    outline: none;
    border-bottom-color: #48604f;
}

.map>.mapimg>iframe {
    outline: none;

}

.lisianthus1 {
    display: none;
}
.lisianthus2 {
    display: none;
}


@media screen and (min-width: 1300px) {

    .lisianthus1 {
        height: 700px;
        position: absolute;
        display: block;
        left: 3%;
        top: 10%;
        transform: scale(-1, 1);
    }

    .lisianthus2 {
        height: 450px;
        position: absolute;
        display: block;
        right: 0%;
        top: 40%;
    }

    .MainDiv {
        position: relative;
        width: 70%;
        left: 15%;
    }

    .contacts > .infos > .icons > div > svg {
        position: relative;
        width: 2em;
        height: 2em;
        filter: invert(14%) sepia(15%) saturate(2198%) hue-rotate(108deg)
            brightness(95%) contrast(91%);
    }

    h4 {
        font-size: 20px;
    
    }

    .form>div>* {
        font-size: 20px;
    }

    .form>button {
        width: 10em;
        height: 3em;
        font-size: 20px;
    }
}

@media screen and (min-width: 900px) and (max-width: 1300px) {

    .lisianthus1 {
        height: 600px;
        position: absolute;
        display: block;
        left: 2%;
        top: 8%;
        transform: scale(-1, 1);
    }

    .lisianthus2 {
        height: 350px;
        position: absolute;
        display: block;
        right: -5%;
        top: 45%;
    }


    .MainDiv {
        position: relative;
        width: 80%;
        left: 10%;
    }

    .contacts > .infos > .icons > div > svg {
        position: relative;
        width: 1.8em;
        height: 1.8em;
        filter: invert(14%) sepia(15%) saturate(2198%) hue-rotate(108deg)
            brightness(95%) contrast(91%);
    }

    h4 {
        font-size: 18px;
    }

    .map>.mapimg>iframe {
        border: none;
        width: 100%;
        box-shadow: 10px 10px 0px 0px #0000003E;
        height: 30em;
    }

    .form>div>* {
        font-size: 18px;
    }

    .form>button {
        width: 9em;
        height: 2.8em;
        font-size: 18px;
    }
}

@media screen and (min-width: 550px) and (max-width: 900px) {
    

    .lisianthus1 {
        height: 380px;
        position: absolute;
        display: block;
        left: 1%;
        top: 8%;
        transform: scale(-1, 1);
    }

    @media screen and (min-width: 700px) and (max-width: 900px) {

        .lisianthus2 {
            height: 220px;
            position: absolute;
            display: block;
            right: -4%;
            top: 50%;
        }
    }

    .MainDiv {
        position: relative;
        width: 90%;
        left: 5%;
    }

    .map>.mapimg>iframe {
        border: none;
        width: 100%;
        box-shadow: 10px 10px 0px 0px #0000003E;
        height: 20em;
    }
    
    

    .contacts > .infos > .icons > div > svg {
        position: relative;
        width: 1.7em;
        height: 1.7em;
        filter: invert(14%) sepia(15%) saturate(2198%) hue-rotate(108deg)
            brightness(95%) contrast(91%);
    }

    h4 {
        font-size: 16px;
    }

    .form>div>* {
        font-size: 13px;
    }

    .form>div {
        width: 80%;
    }

    .form>button {
        width: 8.1em;
        height: 2.4em;
        font-size: 13px;
    }
}

@media screen and (min-width: 350px) and (max-width: 550px) {
    .MainDiv {
        position: relative;
        width: 90%;
        left: 5%;
    }
    .MainHead > h1 {
        text-align: center;
    }

    .text > p {
        text-align: justify;
        width: 100%;
    }

    .text {
        margin-top: 3em;
        width: 100%;
        left: 0%;
    }

    .contacts > .infos > .icons > div > svg {
        position: relative;
        width: 1.40em;
        height: 1.40em;
        filter: invert(14%) sepia(15%) saturate(2198%) hue-rotate(108deg)
            brightness(95%) contrast(91%);
    }

    h4 {
        font-size: 13px;
    }

    .map>.mapimg>iframe {
        border: none;
        width: 100%;
        box-shadow: 7px 7px 0px 0px #0000003E;
        height: 20em;
    }

    .map {
        position: relative;
        display: flex;
        width: 100%;
        left: 0%;
    }
    .form>div>* {
        font-size: 12px;
    }
    .form>div {
        width: 90%;
    }

    .form>button {
        width: 7.9em;
        height: 2.3em;
        font-size: 12px;
    }
}

/* 200 - 350 */
@media screen and (min-width: 200px) and (max-width: 350px) {
    .MainDiv {
        position: relative;
        width: 90%;
        left: 5%;
    }

    .MainHead > h1 {
        text-align: center;
    }

    .text > p {
        text-align: justify;
        width: 100%;
    }
    .text {
        margin-top: 2em;
        width: 100%;
        left: 0%;
    }

    .contacts > .infos > .icons > div > svg {
        position: relative;
        width: 1.1em;
        height: 1.1em;
        filter: invert(14%) sepia(15%) saturate(2198%) hue-rotate(108deg)
            brightness(95%) contrast(91%);
    }

    h4 {
        font-size: 12px;
    }

    .map>.mapimg>iframe {
        border: none;
        width: 100%;
        box-shadow: 5px 5px 0px 0px #0000003E;
        height: 15em;
    }

    .map {
        position: relative;
        display: flex;
        width: 100%;
        left: 0%;
    }

    .form>div>* {
        font-size: 11px;
    }

    .form>div {
        width: 90%;
    }

    .form>button {
        width: 7.7em;
        height: 2.1em;
        font-size: 11px;
    }
}

/* .contacts>.note {
    width: 32%;
} */

/* .contacts>.note>.tips {
    position: relative;
    width: 100%;
    display: flex;
    left: -1em;
}

.contacts>.note>.tips>p {
    width: 85%;
    font-family: 'inter';
    text-align: center;
    color: #48604F;
    transform : rotate(5deg);
    font-size: 15px;
}

.contacts>.note>.arrow {
    margin-top: 2em;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.contacts>.note>.arrow>img {
    filter: invert(33%) sepia(11%) saturate(729%) hue-rotate(87deg) brightness(100%) contrast(92%);
}
 */

/* 
.contacts>.infos>.icons> div {
    position: relative;
    height: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contacts>.infos>.icons>div>img {
    width: 3.2em;
    filter: invert(14%) sepia(15%) saturate(2198%) hue-rotate(108deg) brightness(95%) contrast(91%);
}

.contacts>.infos>.icons> div {
    position: relative;
    height: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
} */

/* .contacts>.infos>.icons>div>img {
    width: 2.5em;
    filter: invert(14%) sepia(15%) saturate(2198%) hue-rotate(108deg) brightness(95%) contrast(91%);
}

.contacts>.infos>.icons> div {
    position: relative;
    height: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contacts>.infos>.icons>.localisation>img {
    width: 2em;
    filter: invert(14%) sepia(15%) saturate(2198%) hue-rotate(108deg) brightness(95%) contrast(91%);
} */




.map>.tips {
    width: 32%;
}

.map>.mapimg {
    position: relative;
    width: 100%;
    margin-bottom: 5em;
}

